import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.webp';
import shopifylogo from '../assets/icons8-shopify-48.webp';
const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="header bg-[#F69A29]">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <div className="logo text-2xl font-bold text-[#AD1E2E]">
                    <Link to="/"><img src={logo} alt="Custom Blend App Logo" className="w-44" width={176} height={56} /></Link>
                </div>
                <nav className="menu hidden md:flex space-x-4">
                    <ul className="flex space-x-4">
                        <li><Link to="/" className="menu-item">Home</Link></li>
                        <li><Link to="/contact" className="menu-item">Contact</Link></li>
                        <li><a
								href="https://apps.shopify.com/custom-blends"
								target="_blank"
								className="bg-white text-[#99ae8b] inline-flex items-center justify-center w-full px-2 text-center border rounded-lg sm:w-auto hover:bg-gray-100 transition duration-300"
							>
								<img
									src={shopifylogo}
									alt="Shopify Logo"
									className="w-4"
								/>
								Install
							</a></li>
                    </ul>
                </nav>
                <div className="md:hidden">
                    <button onClick={handleMenuToggle} className="text-[#AD1E2E]">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>
            </div>
            {isMobileMenuOpen && (
                <nav className="menu md:hidden">
                    <ul className="flex flex-col space-y-2 p-4">
                        <li><Link to="/" className="menu-item">Home</Link></li>
                        <li><Link to="/privacy" className="menu-item">Privacy Policy</Link></li>
                        <li><Link to="/contact" className="menu-item">Contact</Link></li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
