import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import PrivacyPolicy from './PrivacyPolicy';
import Contact from './pages/Contact';
import Page404 from './pages/404';
import BlogArchive from './pages/BlogArchive'; // Import Blog Archive
import SingleBlogPost from './pages/SingleBlogPost'; // Import Single Blog Post
import Header from './partials/header';
import Footer from './partials/footer';
import './App.css'; // Make sure to import your CSS file

function App() {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<BlogArchive />} /> {/* Add Blog Archive route */}
            <Route path="/blog/:id" element={<SingleBlogPost />} /> {/* Add Single Blog Post route */}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
