const demoConfig = {
    'coffee': {
        'title': 'Personalized Coffee Blend',
        'template': 'coffee',
        'includeSearch': true,
        'maxItems': 10,
        'measurement': 'part',
        'showImages': false,
        'defaultImage': 'http://customblend.app/wp-content/uploads/default_image.webp',
        'itemListTitle': 'Available Items',
        'blendTitle': 'Your Custom Blend',
        'searchPlaceholder': 'Search for items',
        'showRangeInput': true,
        'showList': false,
        'locale': 'en',
        'saveBlend': true,
        'loadBlendsUrl': '',
        'totalUnit': 'parts',
        'totalAmount': 100,
        'sizes': [100],
        'increment': 1,
        'merchantBlendsUrl': '',
        'showDescription': true,
    },
    'tea': {
        'title': 'Custom Tea Blend',
        'template': 'tea',
        'includeSearch': true,
        'maxItems': 10,
        'measurement': 'g',
        'showImages': false,
        'defaultImage': 'http://customblend.app/wp-content/uploads/default_image.webp',
        'itemListTitle': 'Available Items',
        'blendTitle': 'Your Custom Blend',
        'searchPlaceholder': 'Search for items',
        'showRangeInput': true,
        'showList': false,
        'locale': 'en',
        'saveBlend': true,
        'loadBlendsUrl': '',
        'totalUnit': 'g',
        'totalAmount': 100,
        'sizes': [100],
        'increment': 1,
        'merchantBlendsUrl': '',
        'showDescription': true,
    },
    'cosmetics': {
        'title': 'Your Custom Cosmetic Blend',
        'template': 'cosmetics',
        'includeSearch': true,
        'maxItems': 10,
        'measurement': 'oz',
        'showImages': false,
        'defaultImage': 'http://customblend.app/wp-content/uploads/default_image.webp',
        'itemListTitle': 'Available Items',
        'blendTitle': 'Your Custom Blend',
        'searchPlaceholder': 'Search for items',
        'showRangeInput': true,
        'showList': false,
        'locale': 'en',
        'saveBlend': true,
        'loadBlendsUrl': '',
        'totalUnit': 'oz',
        'totalAmount': 250,
        'sizes': [250],
        'increment': 1,
        'merchantBlendsUrl': '',
        'showDescription': true,
    },
    'herbs': {
        'title': 'Custom Herbal Tincture',
        'template': 'herbal',
        'includeSearch': true,
        'maxItems': 10,
        'measurement': 'ml',
        'showImages': false,
        'defaultImage': 'http://customblend.app/wp-content/uploads/default_image.webp',
        'itemListTitle': 'Available Items',
        'blendTitle': 'Your Custom Blend',
        'searchPlaceholder': 'Search for items',
        'showRangeInput': true,
        'showList': false,
        'locale': 'en',
        'saveBlend': true,
        'loadBlendsUrl': '',
        'totalUnit': 'ml',
        'totalAmount': 100,
        'sizes': [100],
        'increment': 10,
        'merchantBlendsUrl': '',
        'showDescription': true,
    },
    'flowers': {
        'title': 'Custom Floral Arrangement',
        'template': 'flowers',
        'includeSearch': true,
        'maxItems': 10,
        'measurement': 'stem',
        'showImages': false,
        'defaultImage': 'http://customblend.app/wp-content/uploads/default_image.webp',
        'itemListTitle': 'Available Items',
        'blendTitle': 'Your Custom Blend',
        'searchPlaceholder': 'Search for items',
        'showRangeInput': true,
        'showList': false,
        'locale': 'en',
        'saveBlend': true,
        'loadBlendsUrl': '',
        'totalUnit': 'stems',
        'totalAmount': 100,
        'sizes': [100],
        'increment': 10,
        'merchantBlendsUrl': '',
        'showDescription': true,
    },
};

export {demoConfig};

const items = {
    'coffee': [
        {
            id: 1,
            name: 'Arabica Coffee Beans',
            description: 'A rich and flavorful coffee bean from Ethiopia.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee1.webp',
            keywords: 'coffee, arabica, beans, ethiopia, rich, flavorful'
        },
        {
            id: 2,
            name: 'Robusta Coffee Beans',
            description: 'A bold and strong coffee bean from Indonesia.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee2.webp',
            keywords: 'coffee, robusta, beans, indonesia, bold, strong'
        },
        {
            id: 3,
            name: 'Columbian Coffee Beans',
            description: 'A smooth and balanced coffee bean from Columbia.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee3.webp',
            keywords: 'coffee, columbian, beans, smooth, balanced'
        },
        {
            id: 4,
            name: 'Guatemalan Coffee Beans',
            description: 'A sweet and fruity coffee bean from Guatemala.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee4.webp',
            keywords: 'coffee, guatemalan, beans, sweet, fruity'
        },
        {
            id: 5,
            name: 'Kenyan Coffee Beans',
            description: 'A bright and acidic coffee bean from Kenya.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee5.webp',
            keywords: 'coffee, kenyan, beans, bright, acidic'
        },
        {
            id: 6,
            name: 'Oat Milk',
            description: 'A smooth and nutty coffee bean from Hawaii.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee6.webp',
            keywords: 'coffee, oat milk, smooth, nutty, hawaii'
        },
        {
            id: 7,
            name: 'Almond Milk',
            description: 'A sweet and creamy coffee bean from Brazil.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee7.webp',
            keywords: 'coffee, almond milk, sweet, creamy, brazil'
        },
        {
            id: 8,
            name: 'Soy Milk',
            description: 'A rich and flavorful coffee bean from Ethiopia.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee8.webp',
            keywords: 'coffee, soy milk, rich, flavorful, ethiopia'
        },
        {
            id: 9,
            name: 'Coconut Milk',
            description: 'A bold and strong coffee bean from Indonesia.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee9.webp',
            keywords: 'coffee, coconut milk, bold, strong, indonesia'
        },
        {
            id: 10,
            name: 'Cashew Milk',
            description: 'A smooth and balanced coffee bean from Columbia.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, cashew milk, smooth, balanced, columbia'
        },
        {
            id: 11,
            name: 'Honey',
            description: 'A sweet and natural sweetener for coffee.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, honey, sweet, natural, sweetener'
        },
        {
            id: 12,
            name: 'Maple Syrup',
            description: 'A rich and natural sweetener for coffee.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, maple syrup, rich, natural, sweetener'
        },
        {
            id: 13,
            name: 'Cinnamon',
            description: 'A warm and spicy flavoring for coffee.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, cinnamon, warm, spicy, flavoring'
        },
        {
            id: 14,
            name: 'Vanilla Extract',
            description: 'A sweet and fragrant flavoring for coffee.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, vanilla extract, sweet, fragrant, flavoring'
        },
        {
            id: 15,
            name: 'Caramel Syrup',
            description: 'A rich and sweet syrup for coffee.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, caramel syrup, rich, sweet, syrup'
        },
        {
            id: 16,
            name: 'Chocolate Syrup',
            description: 'A smooth and sweet syrup for coffee.',
            image_url: 'http://customblend.app/wp-content/uploads/coffee10.webp',
            keywords: 'coffee, chocolate syrup, smooth, sweet, syrup'
        }
    ],
    'herbs': [
        {
            id: 1,
            name: 'Lavender',
            description: 'A fragrant and calming herb from France.',
            image_url: 'http://customblend.app/wp-content/uploads/herb1.webp',
            keywords: 'herb, lavender, fragrant, calming, france'
        },
        {
            id: 2,
            name: 'Chamomile',
            description: 'A soothing and relaxing herb from Egypt.',
            image_url: 'http://customblend.app/wp-content/uploads/herb2.webp',
            keywords: 'herb, chamomile, soothing, relaxing, egypt'
        },
        {
            id: 3,
            name: 'Peppermint',
            description: 'A refreshing and invigorating herb from the US.',
            image_url: 'http://customblend.app/wp-content/uploads/herb3.webp',
            keywords: 'herb, peppermint, refreshing, invigorating, US'
        },
        {
            id: 4,
            name: 'Echinacea',
            description: 'An immune-boosting herb from Canada.',
            image_url: 'http://customblend.app/wp-content/uploads/herb4.webp',
            keywords: 'herb, echinacea, immune-boosting, canada'
        },
        {
            id: 5,
            name: 'Ginseng',
            description: 'An energizing and revitalizing herb from Korea.',
            image_url: 'http://customblend.app/wp-content/uploads/herb5.webp',
            keywords: 'herb, ginseng, energizing, revitalizing, korea'
        },
        {
            id: 6,
            name: 'Valerian',
            description: 'A calming and sedative herb from Germany.',
            image_url: 'http://customblend.app/wp-content/uploads/herb6.webp',
            keywords: 'herb, valerian, calming, sedative, germany'
        },
        {
            id: 7,
            name: 'St. John\'s Wort',
            description: 'A mood-boosting herb from Greece.',
            image_url: 'http://customblend.app/wp-content/uploads/herb7.webp',
            keywords: 'herb, st. john\'s wort, mood-boosting, greece'
        },
        {
            id: 8,
            name: 'Turmeric',
            description: 'An anti-inflammatory herb from India.',
            image_url: 'http://customblend.app/wp-content/uploads/herb8.webp',
            keywords: 'herb, turmeric, anti-inflammatory, india'
        },
        {
            id: 9,
            name: 'Ginger',
            description: 'A warming and spicy herb from China.',
            image_url: 'http://customblend.app/wp-content/uploads/herb9.webp',
            keywords: 'herb, ginger, warming, spicy, china'
        },
        {
            id: 10,
            name: 'Rosemary',
            description: 'A fragrant and savory herb from Italy.',
            image_url: 'http://customblend.app/wp-content/uploads/herb10.webp',
            keywords: 'herb, rosemary, fragrant, savory, italy'
        }
    ],
    'flowers': [
        {
            id: 1,
            name: 'Roses',
            description: 'A classic and romantic flower from England.',
            image_url: 'http://customblend.app/wp-content/uploads/flower1.webp',
            keywords: 'flower, roses, classic, romantic, england'
        },
        {
            id: 2,
            name: 'Lilies',
            description: 'A graceful and elegant flower from Japan.',
            image_url: 'http://customblend.app/wp-content/uploads/flower2.webp',
            keywords: 'flower, lilies, graceful, elegant, japan'
        },
        {
            id: 3,
            name: 'Tulips',
            description: 'A vibrant and colorful flower from Holland.',
            image_url: 'http://customblend.app/wp-content/uploads/flower3.webp',
            keywords: 'flower, tulips, vibrant, colorful, holland'
        },
        {
            id: 4,
            name: 'Daisies',
            description: 'A cheerful and playful flower from the US.',
            image_url: 'http://customblend.app/wp-content/uploads/flower4.webp',
            keywords: 'flower, daisies, cheerful, playful, US'
        },
        {
            id: 5,
            name: 'Orchids',
            description: 'An exotic and mysterious flower from Thailand.',
            image_url: 'http://customblend.app/wp-content/uploads/flower5.webp',
            keywords: 'flower, orchids, exotic, mysterious, thailand'
        },
        {
            id: 6,
            name: 'Sunflowers',
            description: 'A bright and sunny flower from Spain.',
            image_url: 'http://customblend.app/wp-content/uploads/flower6.webp',
            keywords: 'flower, sunflowers, bright, sunny, spain'
        },
        {
            id: 7,
            name: 'Peonies',
            description: 'A lush and fragrant flower from France.',
            image_url: 'http://customblend.app/wp-content/uploads/flower7.webp',
            keywords: 'flower, peonies, lush, fragrant, france'
        },
        {
            id: 8,
            name: 'Hydrangeas',
            description: 'A delicate and pastel flower from Korea.',
            image_url: 'http://customblend.app/wp-content/uploads/flower8.webp',
            keywords: 'flower, hydrangeas, delicate, pastel, korea'
        },
        {
            id: 9,
            name: 'Daffodils',
            description: 'A cheerful and sunny flower from Wales.',
            image_url: 'http://customblend.app/wp-content/uploads/flower9.webp',
            keywords: 'flower, daffodils, cheerful, sunny, wales'
        },
        {
            id: 10,
            name: 'Carnations',
            description: 'A fragrant and long-lasting flower from Italy.',
            image_url: 'http://customblend.app/wp-content/uploads/flower10.webp',
            keywords: 'flower, carnations, fragrant, long-lasting, italy'
        }
    ],
    'cosmetics': [
        {
            id: 1,
            name: 'Jojoba Oil',
            description: 'A nourishing and moisturizing oil from Mexico.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic1.webp',
            keywords: 'cosmetic, jojoba oil, nourishing, moisturizing, mexico'
        },
        {
            id: 2,
            name: 'Shea Butter',
            description: 'A rich and creamy butter from Africa.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic2.webp',
            keywords: 'cosmetic, shea butter, rich, creamy, africa'
        },
        {
            id: 3,
            name: 'Coconut Oil',
            description: 'A versatile and hydrating oil from the Philippines.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic3.webp',
            keywords: 'cosmetic, coconut oil, versatile, hydrating, philippines'
        },
        {
            id: 4,
            name: 'Argan Oil',
            description: 'A luxurious and rejuvenating oil from Morocco.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic4.webp',
            keywords: 'cosmetic, argan oil, luxurious, rejuvenating, morocco'
        },
        {
            id: 5,
            name: 'Rosehip Oil',
            description: 'A regenerative and healing oil from Chile.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic5.webp',
            keywords: 'cosmetic, rosehip oil, regenerative, healing, chile'
        },
        {
            id: 6,
            name: 'Aloe Vera Gel',
            description: 'A soothing and cooling gel from Hawaii.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic6.webp',
            keywords: 'cosmetic, aloe vera gel, soothing, cooling, hawaii'
        },
        {
            id: 7,
            name: 'Vitamin E Oil',
            description: 'An antioxidant and anti-aging oil from Australia.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic7.webp',
            keywords: 'cosmetic, vitamin e oil, antioxidant, anti-aging, australia'
        },
        {
            id: 8,
            name: 'Hyaluronic Acid',
            description: 'A hydrating and plumping acid from Japan.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic8.webp',
            keywords: 'cosmetic, hyaluronic acid, hydrating, plumping, japan'
        },
        {
            id: 9,
            name: 'Green Tea Extract',
            description: 'An antioxidant and anti-inflammatory extract from China.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic9.webp',
            keywords: 'cosmetic, green tea extract, antioxidant, anti-inflammatory, china'
        },
        {
            id: 10,
            name: 'Rosewater',
            description: 'A fragrant and refreshing water from Bulgaria.',
            image_url: 'http://customblend.app/wp-content/uploads/cosmetic10.webp',
            keywords: 'cosmetic, rosewater, fragrant, refreshing, bulgaria'
        }
    ],
    'tea': [
        {
            id: 1,
            name: 'Green Tea',
            description: 'A light and refreshing tea from China.',
            image_url: 'http://customblend.app/wp-content/uploads/tea1.webp',
            keywords: 'tea, green tea, light, refreshing, china'
        },
        {
            id: 2,
            name: 'Black Tea',
            description: 'A bold and robust tea from India.',
            image_url: 'http://customblend.app/wp-content/uploads/tea2.webp',
            keywords: 'tea, black tea, bold, robust, india'
        },
        {
            id: 3,
            name: 'White Tea',
            description: 'A delicate and subtle tea from Japan.',
            image_url: 'http://customblend.app/wp-content/uploads/tea3.webp',
            keywords: 'tea, white tea, delicate, subtle, japan'
        },
        {
            id: 4,
            name: 'Oolong Tea',
            description: 'A fragrant and floral tea from Taiwan.',
            image_url: 'http://customblend.app/wp-content/uploads/tea4.webp',
            keywords: 'tea, oolong tea, fragrant, floral, taiwan'
        },
        {
            id: 5,
            name: 'Herbal Tea',
            description: 'A soothing and calming tea from Egypt.',
            image_url: 'http://customblend.app/wp-content/uploads/tea5.webp',
            keywords: 'tea, herbal tea, soothing, calming, egypt'
        },
        {
            id: 6,
            name: 'Chai Tea',
            description: 'A spicy and aromatic tea from India.',
            image_url: 'http://customblend.app/wp-content/uploads/tea6.webp',
            keywords: 'tea, chai tea, spicy, aromatic, india'
        },
        {
            id: 7,
            name: 'Matcha Tea',
            description: 'A vibrant and energizing tea from Japan.',
            image_url: 'http://customblend.app/wp-content/uploads/tea7.webp',
            keywords: 'tea, matcha tea, vibrant, energizing, japan'
        },
        {
            id: 8,
            name: 'Earl Grey Tea',
            description: 'A citrusy and floral tea from England.',
            image_url: 'http://customblend.app/wp-content/uploads/tea8.webp',
            keywords: 'tea, earl grey tea, citrusy, floral, england'
        },
        {
            id: 9,
            name: 'Jasmine Tea',
            description: 'A fragrant and floral tea from China.',
            image_url: 'http://customblend.app/wp-content/uploads/tea9.webp',
            keywords: 'tea, jasmine tea, fragrant, floral, china'
        },
        {
            id: 10,
            name: 'Rooibos Tea',
            description: 'A sweet and nutty tea from South Africa.',
            image_url: 'http://customblend.app/wp-content/uploads/tea10.webp',
            keywords: 'tea, rooibos tea, sweet, nutty, south africa'
        }
    ]
};

export { items };
