import React, { useState, useEffect } from 'react';
import './index.css';
import Dropdown from './DropDown.jsx';

function BlendMaker({ config = {}, storeId = '', defaultSize = 100, sizes = [100], propertyName = '', color = '#4CAF50', demo_mode = false, demoItems }) {
    const [currentConfig, setConfig] = useState(config);
    const [items, setItems] = useState([]);
    const [blend, setBlend] = useState([]);
    const [totalValue, setTotalValue] = useState(0);
    const [error, setError] = useState(null);
    const [blendName, setBlendName] = useState('');
    const [blendDescription, setBlendDescription] = useState('');
    const [blendId, setBlendId] = useState('');
    const [size, setSize] = useState(defaultSize);
    const [merchantBlends, setMerchantBlends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [currentSize, setCurrentSize] = useState(defaultSize);

    const customClasses = {};
    currentConfig.customClasses = {
        container: '',
        title: '',
        select: '',
        ...customClasses,
    };

    const localization = {
        en: {
            noItemsFound: "No items found.",
            addItem: "Add Item",
            removeItem: "Remove",
            totalPercentage: `Total`,
            blendTitle: "Your Blend",
            blendNamePlaceholder: "Enter blend name",
            blendDescriptionPlaceholder: "Enter blend description or special requests",
            reset: "Reset",
        },
        es: {
            noItemsFound: "No se encontraron artículos.",
            addItem: "Agregar artículo",
            removeItem: "Eliminar",
            totalPercentage: `Porcentaje total (${currentConfig.totalUnit})`,
            blendTitle: "Tu mezcla",
            blendNamePlaceholder: "Ingresa el nombre de la mezcla",
            blendDescriptionPlaceholder: "Ingresa la descripción de la mezcla o solicitudes especiales",
            reset: "Reiniciar",
        }
    };

    const texts = localization[currentConfig.locale] || localization['en'];

    useEffect(() => {
        setConfig(config);
        setSize(config.totalAmount || defaultSize);
        setBlend([]);
        if (demo_mode) {
            setItems(demoItems);
            setLoading(false);
            if (config.includeSearch && demoItems.length > 0) {
                const blendData = demoItems.map(item => ({ ...item, amount: 0, 'unit': config.totalUnit, 'image_url': getImageUrl(item.image_url) }));
                setBlend(blendData);
            }
        } else if (storeId && !apiLoaded) {
            fetch(`https://api.customblend.app/wp-json/custom-blend/v1/stores/${storeId}/full`)
                .then(response => response.json())
                .then(data => {
                    const filteredItems = data.items;
                    setItems(filteredItems);
                    setApiLoaded(true);
                    setConfig(prevConfig => ({ ...prevConfig, ...data.settings }));
                    if (!parseToBool(config.includeSearch) && filteredItems.length > 0) {
                        const blendData = filteredItems.map(item => ({ ...item, amount: 0, 'unit': config.totalUnit, 'image_url': getImageUrl(item.image_url) }));
                        setBlend(blendData);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching items:', error);
                    setError('Error fetching items');
                });

            if (config.merchantBlendsUrl) {
                fetch(config.merchantBlendsUrl)
                    .then(response => response.json())
                    .then(data => setMerchantBlends(data))
                    .catch(error => {
                        console.error('Error fetching merchant blends:', error);
                        setError('Error fetching merchant blends');
                    });
            }
        }
    }, [config, demo_mode, demoItems, storeId, apiLoaded, defaultSize]);

    useEffect(() => {
        if (document.getElementById('custom-blend-properties')) {
            let blendString = '';
            blend.forEach((item, index) => {
                blendString += `${item.name}: ${item.amount}${config.totalUnit}`;
                if (index < blend.length - 1) {
                    blendString += ', ';
                }
            });
            document.getElementById('custom-blend-properties').value = blendString;
        }
    }, [blend, config.totalUnit]);

    useEffect(() => {
        const handleSizeChange = () => {
            setCurrentSize(window.blendMakerSize);
        };

        window.addEventListener('blendMakerSizeChange', handleSizeChange);

        return () => {
            window.removeEventListener('blendMakerSizeChange', handleSizeChange);
        };
    }, []);

    useEffect(() => {
        setSize(currentSize);
        handleReset();
    }, [currentSize]);

    const handlePercentageChange = (id, percentage) => {
        let parsedPercentage = parseFloat(percentage);
        if (parsedPercentage < 0) parsedPercentage = 0;
        if (parsedPercentage > size) parsedPercentage = size;

        const newBlend = blend.map(item =>
            item.id === id ? { ...item, amount: parsedPercentage } : item
        );

        const total = newBlend.reduce((acc, item) => acc + item.amount, 0);
        if (total <= size) {
            setBlend(newBlend);
            setTotalValue(total);
        }
    };

    const getImageUrl = (imageUrl) => {
        if (imageUrl) {
            return imageUrl;
        }
        return config.defaultImage;
    };

    const parseToBool = (value) => {
        if (typeof value === 'boolean') return value;
        if (typeof value === 'string') return value.trim().toLowerCase() === 'true';
        return !!value;
    };

    const handleAddItem = (item) => {
        if (!blend.find(blendItem => blendItem.id === item.id) && blend.length < currentConfig.maxItems) {
            setBlend([...blend, { ...item, amount: 0, 'unit': config.totalUnit, 'image_url': getImageUrl(item.image_url) }]);
        }
    };

    const handleSizeChange = (selectedOption) => {
        const newSize = selectedOption.value;
        const scaleFactor = newSize / size;
        const scaledBlend = blend.map(item => ({
            ...item,
            amount: Math.min(item.amount * scaleFactor, newSize),
            'unit': config.totalUnit,
            'image_url': getImageUrl(item.image_url)
        }));
        const scaledTotal = scaledBlend.reduce((acc, item) => acc + item.amount, 0);
        setSize(newSize);
        setBlend(scaledBlend);
        setTotalValue(scaledTotal);
    };

    const handleIncrementChange = (id, increment) => {
        const newBlend = blend.map(item =>
            item.id === id ? { ...item, amount: item.amount + increment, 'unit': config.totalUnit, 'image_url': getImageUrl(item.image_url) } : item
        );
        const total = newBlend.reduce((acc, item) => acc + item.amount, 0);
        if (total <= size) {
            setBlend(newBlend);
            setTotalValue(total);
        }
    };

    const handleRemoveItem = (id) => {
        setBlend(blend.filter(item => item.id !== id));
    };

    const handleReset = () => {
        setBlend(blend.map(item => ({ ...item, amount: 0, 'unit': config.totalUnit, 'image_url': getImageUrl(item.image_url) })));
        setTotalValue(0);
    };

    const loadBlends = () => {
        if (currentConfig.loadBlendsUrl) {
            fetch(currentConfig.loadBlendsUrl)
                .then(response => response.json())
                .then(data => {
                    if (data && Array.isArray(data)) {
                        const total = data.reduce((acc, item) => acc + item.amount, 0);
                        setTotalValue(total);
                    }
                })
                .catch(error => {
                    console.error('Error loading blends:', error);
                    setError('Error loading blends');
                });
        }
    };

    const customRenderItem = (item, handleSelect) => (
        <div key={item.id} onClick={() => { handleAddItem(item); handleSelect(item); }} className="cbb-search-item">
            {currentConfig.showImages && <img src={item.image_url ? item.image_url : currentConfig.defaultImage} alt={item.name} className="item-image" />}
            <span>{item.name}</span>
        </div>
    );

    const thumbStyle = {
        background: color || '#4CAF50',
    };

    const DeleteIcon = () => (
        <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
        </svg>
    );

    return (
        (storeId || demo_mode) && !loading && (
            <div className={`cbb-container`}>
                {currentConfig.title && (
                    <h2 className={`cbb-title`}>{currentConfig.title || "Custom Blend Maker"}</h2>
                )}
                <input type="hidden" name={`properties[${propertyName}]`} id="custom-blend-properties" />
                {currentConfig.blendName && (
                    <div className="blend-name-container">
                        <h3>{texts.blendTitle}</h3>
                        <input
                            type="text"
                            placeholder={texts.blendNamePlaceholder}
                            value={blendName}
                            onChange={(e) => setBlendName(e.target.value)}
                            className="blend-name-input"
                        />
                        {currentConfig.showDescription && (
                            <textarea
                                placeholder={texts.blendDescriptionPlaceholder}
                                value={blendDescription}
                                onChange={(e) => setBlendDescription(e.target.value)}
                                className="blend-description-input"
                            />
                        )}
                    </div>
                )}
    
                {parseToBool(currentConfig.includeSearch) && (
                    <Dropdown
                        items={items}
                        onSelect={(item) => { }}
                        placeholder={currentConfig.searchPlaceholder}
                        customRenderItem={customRenderItem}
                    />
                )}
    
                {currentConfig.merchantBlendsUrl && (
                    <button onClick={loadBlends} className="button load-blends-button">Load Saved Blends</button>
                )}

                {currentConfig.sizes.length > 1 && (
                    {/*<Select onChange={handleSizeChange} className="cbb-size-select"
                        options={currentConfig.sizes.map(size => ({ value: size, label: `${size} ${currentConfig.measurement}` }))}
                    />*/}
                )}
                {currentConfig.sizes.length === 1 && (
                    <></>
                )}
                <input
                    id="cbb-size-input"
                    type="hidden"
                    style={{ display: 'block' }}
                    onChange={(e) => setCurrentSize(e.target.value)}
                />
                {currentConfig.showList && items && items.length > 0 && (
                    <div className='cbb-item-list'>
                        {currentConfig.itemListTitle && <h3>{currentConfig.itemListTitle}</h3>}
                        <ul>
                            {items.map(item => (
                                <li key={item.id} onClick={() => handleAddItem(item)} className="item">
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {items && items.length === 0 && <p>{texts.noItemsFound}</p>}
                <div className='cbb-blend'>
                    {currentConfig.blendTitle && <h3>{currentConfig.blendTitle}</h3>}
                    <p>{texts.totalPercentage}: {totalValue} {currentConfig.totalUnit}</p>
                    {blend.map(item => (
                        <div key={item.id} className="blend-item">
                            <div className="blend-item-details">
                                {currentConfig.showImages && <img src={item.image_url ? item.image_url : currentConfig.defaultImage} alt={item.name} className="item-image" />}
                                <span>{item.name}</span>
                                <span className='blend-count-wrapper'>
                                    <input
                                        type="number"
                                        value={item.amount}
                                        min="0"
                                        max={size}
                                        step={currentConfig.increment}
                                        onChange={(e) => handlePercentageChange(item.id, e.target.value)}
                                        className="percentage-input"
                                    />
                                    {parseToBool(currentConfig.includeSearch) && (
                                        <button onClick={() => handleRemoveItem(item.id)} className="remove-button">
                                            <DeleteIcon />
                                        </button>
                                    )}
                                </span>
                            </div>
                            <div className="blend-item-controls">
                                {currentConfig.showRangeInput && (
                                    <input
                                        type="range"
                                        value={item.amount}
                                        min="0"
                                        max={size}
                                        step={currentConfig.increment}
                                        onChange={(e) => handlePercentageChange(item.id, e.target.value)}
                                        className="percentage-range"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <button onClick={handleReset} className="button reset-button">{texts.reset}</button>
                {error && <div className="error">{error}</div>}
            </div>
        )
    );
}

export default BlendMaker;
