import React, { useState, useRef } from 'react';
import useDropdown from './useDropdown.jsx';

const Dropdown = ({
    items,
    onSelect,
    placeholder = 'Select an item',
    maxItemsToShow = 0,
    caseSensitive = false,
    customRenderItem
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    useDropdown(dropdownRef, () => setIsOpen(false));

    const filterItems = (items, searchTerm, caseSensitive) => {
        return items.filter(item =>
            caseSensitive
                ? item.keywords.includes(searchTerm)
                : item.keywords.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    items.sort((a, b) => a.name.localeCompare(b.name));

    const filteredItems = maxItemsToShow > 0 ? filterItems(items, searchTerm, caseSensitive).slice(0, maxItemsToShow) : filterItems(items, searchTerm, caseSensitive);

    const handleSelect = item => {
        onSelect(item);
        setIsOpen(false);
        setSearchTerm('');
    };

    return (
        <div className="dropdown-container" ref={dropdownRef}>
            <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                {placeholder}
            </div>
            {isOpen && (
                <>
                    <div className="cbb-search-wrapper">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder="Search..."
                        />
                    </div>
                    <ul className="dropdown-list">
                        {filteredItems.map(item =>
                            customRenderItem ? (
                                customRenderItem(item, handleSelect)
                            ) : (
                                <li className="dropdown-list-item" key={item.id} onClick={() => handleSelect(item)}>
                                    {item.image && <img src={item.image} alt={item.name} />}
                                    <span>{item.name}</span>
                                </li>
                            )
                        )}
                    </ul>
                </>
            )}
        </div>
    );
};

export default Dropdown;