import React, { useState } from "react";
import { Link } from "react-router-dom";
import contactImage from "../assets/10_Customer_Support.webp";
import { preloadImages } from "../modules/helpers";
import { Helmet } from "react-helmet";
preloadImages([contactImage]);
const subjects = ["Feature Request", "I found a bug", "General Help"];

function Contact() {
	const handleSubjectClick = (subject) => {
		if (window.Beacon) {
			window.Beacon("prefill", {
				name: "",
				email: "",
				subject: subject,
				text: "",
				fields: [
					{
						id: 5678, // dropdown field ID
						value: 1234, // option ID
					},
					{
						id: 5679, // date field ID
						value: "YYYY-MM-DD", // YYYY-MM-DD format
					},
					{
						id: 5680, // single, multi-line, or number field ID
						value: subject, // value expressed as a string
					},
				],
			});
			window.Beacon("open", { mode: "email" });
		}
	};

	return (
		<div className="container mx-auto px-4 py-8 bg-white my-10">
			<Helmet>
				<title>Contact Us - Custom Blend App</title>
				<meta
					name="description"
					content="Get in touch with the Custom Blend App team. We're here to help you with any questions or support you need to create personalized product blends for your store."
				/>
				<meta
					name="keywords"
					content="contact, support, custom blends, personalized products, e-commerce, Shopify, product customization"
				/>
				<meta name="author" content="Custom Blend" />
				<meta property="og:title" content="Contact Us - Custom Blend App" />
				<meta
					property="og:description"
					content="Get in touch with the Custom Blend App team. We're here to help you with any questions or support you need to create personalized product blends for your store."
				/>
				<meta
					property="og:image"
					content={window.location.origin + "/images/contact-banner.png"}
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content={window.location.href} />
				<meta name="twitter:title" content="Contact Us - Custom Blend App" />
				<meta
					name="twitter:description"
					content="Get in touch with the Custom Blend App team. We're here to help you with any questions or support you need to create personalized product blends for your store."
				/>
				<meta
					name="twitter:image"
					content={window.location.origin + "/images/contact-banner.png"}
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				<div>
					<header className="mb-6">
						<h1 className="text-4xl font-bold mb-2">
							Contact Custom Blend Developers
						</h1>
					</header>
					<section>
						<h2 className="text-2xl font-semibold mb-4">Select a Subject:</h2>
						<p className="mb-6">
							Do you need help with the Custom Blend app? Choose a subject
							below.
						</p>
						<ul className="space-y-4">
							{subjects.map((subject, index) => (
								<li key={index}>
									<a
										href="#"
										onClick={(e) => {
											e.preventDefault();
											handleSubjectClick(subject);
										}}
										className="block p-4 bg-gray-200 hover:bg-gray-300 rounded-lg text-lg font-medium"
									>
										{subject}
									</a>
								</li>
							))}
						</ul>
					</section>
				</div>
				<div className="flex justify-center items-center">
					<img
						src={contactImage}
						alt="Contact Us"
						className="w-full md:w-3/4"
						height={456}
						width={456}
					/>
				</div>
			</div>
		</div>
	);
}

export default Contact;
