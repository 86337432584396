import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/BlogArchive.scss"; // Import your styles here
import posts from "../assets/data/article-index.json";
const BlogArchive = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-archive">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">Blog Archive</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentPosts.map((post) => (
            <div key={post.id} className="bg-white shadow-md rounded-lg overflow-hidden">
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-2">{post.title}</h2>
                <p className="text-gray-700 mb-4">{post.excerpt}</p>
                <p className="text-gray-500 text-sm">{new Date(post.date).toDateString()}</p>
                <Link to={`/blog/${post.id}`} className="text-[#F69A29] hover:text-[#AD1E2E] transition duration-300 mt-4 inline-block">Read More</Link>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={posts.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="mt-8">
      <ul className="pagination flex justify-center">
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${currentPage === number ? 'bg-[#F69A29] text-white' : 'bg-white text-[#F69A29]'} rounded-full mx-1`}>
            <button onClick={() => paginate(number)} className="page-link py-2 px-4 rounded-full focus:outline-none">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default BlogArchive;
