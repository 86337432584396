import React from "react";
import { useDocumentTitle } from "./modules/helpers";
import { Helmet } from "react-helmet";
function PrivacyPolicy() {
	useDocumentTitle("Privacy Policy", {
		title: "Privacy Policy - Custom Blend Options",
		description: "Privacy Policy for Custom Blend Options",
	});

	return (
		<div className="privacy-policy">
			<Helmet>
				<title>Privacy Policy - Custom Blend App</title>
				<meta
					name="description"
					content="Read the Custom Blend App privacy policy to understand how we handle your data and protect your privacy while using our services to create personalized product blends."
				/>
				<meta
					name="keywords"
					content="privacy policy, data protection, custom blends, personalized products, e-commerce, Shopify, product customization"
				/>
				<meta name="author" content="Custom Blend" />
				<meta property="og:title" content="Privacy Policy - Custom Blend App" />
				<meta
					property="og:description"
					content="Read the Custom Blend App privacy policy to understand how we handle your data and protect your privacy while using our services to create personalized product blends."
				/>
				<meta
					property="og:image"
					content={window.location.origin + "/images/privacy-banner.png"}
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content={window.location.href} />
				<meta
					name="twitter:title"
					content="Privacy Policy - Custom Blend App"
				/>
				<meta
					name="twitter:description"
					content="Read the Custom Blend App privacy policy to understand how we handle your data and protect your privacy while using our services to create personalized product blends."
				/>
				<meta
					name="twitter:image"
					content={window.location.origin + "/images/privacy-banner.png"}
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<div className="container mx-auto px-4 py-8 bg-white my-10">
				<header className="mb-6">
					<h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
				</header>
				<section className="max-w-3xl mx-auto">
					<p className="mb-4">Effective Date: June 22nd 2024</p>
					<p className="mb-6">
						Karizmatic LLC ("we," "us," "our") operates the Custom Blend Options
						app ("App") and is committed to protecting the privacy of our users.
						This Privacy Policy describes how we collect, use, and protect your
						information when you use our App through the Shopify API.
					</p>

					<h2 className="text-2xl font-semibold mb-4">
						Information We Collect
					</h2>
					<p className="mb-4">
						Through the Shopify API, our App collects the following information:
					</p>
					<ul className="list-disc list-inside mb-6">
						<li>Shop Name</li>
						<li>App Subscription</li>
						<li>Owner Name</li>
						<li>Owner Email</li>
					</ul>
					<p className="mb-6">
						Additionally, any images you upload to our App are stored on our
						server. These images are deleted when you remove them from the App
						or after 30 days following the uninstallation of the App, at which
						point all related data is removed from our servers.
					</p>

					<h2 className="text-2xl font-semibold mb-4">Use of Information</h2>
					<p className="mb-6">
						The information we collect is solely used for the functionality and
						operation of the Custom Blend Options App and your Shopify store. We
						do not use Cookies for tracking purposes.
					</p>

					<h2 className="text-2xl font-semibold mb-4">Data Retention</h2>
					<p className="mb-6">
						We retain your data as long as you use the App. After you uninstall
						the App, we retain your data for up to 30 days. After this period,
						all your data, including any uploaded images, will be permanently
						deleted from our servers.
					</p>

					<h2 className="text-2xl font-semibold mb-4">Data Protection</h2>
					<p className="mb-6">
						We are committed to ensuring that your information is secure. To
						prevent unauthorized access or disclosure, we have put in place
						suitable physical, electronic, and managerial procedures to
						safeguard and secure the information we collect online.
					</p>

					<h2 className="text-2xl font-semibold mb-4">Information Sharing</h2>
					<p className="mb-6">
						We do not sell, trade, or otherwise transfer to outside parties your
						Personally Identifiable Information. This does not include trusted
						third parties who assist us in operating our App, conducting our
						business, or servicing you, so long as those parties agree to keep
						this information confidential.
					</p>

					<h2 className="text-2xl font-semibold mb-4">European Users</h2>
					<p className="mb-6">
						Our operations are based in the United States, and your information
						will be processed and stored in the United States. By using the App,
						you consent to the transfer of your data to the United States.
					</p>

					<h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
					<p className="mb-4">
						If you have any questions or concerns about this Privacy Policy or
						our data practices, please contact us at:
					</p>
					<address className="mb-6">
						Karizmatic LLC
						<br />
						25 SE 2nd Ave
						<br />
						Ste 550 #576
						<br />
						Miami, FL 33131
						<br />
						Email:{" "}
						<a href="mailto:support@suiteplugins.com">
							support[@]suiteplugins.com
						</a>
						<br />
					</address>

					<h2 className="text-2xl font-semibold mb-4">
						Changes to this Privacy Policy
					</h2>
					<p className="mb-6">
						We may update this Privacy Policy from time to time. We will notify
						you of any changes by posting the new Privacy Policy on this page.
						You are advised to review this Privacy Policy periodically for any
						changes.
					</p>

					<p className="mb-6">
						By using the Custom Blend Options App, you acknowledge that you have
						read and understood this Privacy Policy and agree to our collection,
						use, and disclosure practices, as well as any other activities
						described in this Privacy Policy.
					</p>

					<p className="mb-6">Thank you for using Custom Blend Options.</p>
				</section>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
