import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.scss";
import image from "./assets/calculator-preview.webp";
import addItems from "./assets/update-item-2.webp";
import Customizer from "./assets/customizer.webp";
import FeatureSection from "./partials/featureSection";
import BlendMaker from "./modules/BlendMaker/BlendMaker";
import { demoConfig, items } from "./modules/demoConfig";
import shopifylogo from "./assets/icons8-shopify-48.webp";
import { Helmet } from 'react-helmet';
function LandingPage() {
	const [demo, setDemo] = useState("");
	const [config, setConfig] = useState({});
	const [ingredients, setIngredients] = useState({});
	const [demoSelected, setDemoSelected] = useState(false);

	const updateBlendConfig = (e) => {
		if (!e.target.value) {
			setDemoSelected(false);
			return;
		}
		setDemo(e.target.value);
		setIngredients(items[e.target.value]);
		setConfig(demoConfig[e.target.value]);
		setDemoSelected(true);
	};

	useEffect(() => {
		setDemo("coffee");
		setConfig(demoConfig["coffee"]);
		setIngredients(items["coffee"]);
		setTimeout(() => {
			setDemoSelected(true);
		}, 1000);
		
	}, []);


	return (
		<div className="landing-page">
			<Helmet>
				<title>Custom Blend App - Personalize Your Products</title>
				<meta name="description" content="Discover the Custom Blend App - the ultimate tool for creating unique, personalized product blends for your store. Enhance customer experience and boost sales with ease." />
				<meta name="keywords" content="custom blends, personalized products, e-commerce, Shopify, product customization, customer experience" />
				<meta name="author" content="Custom Blend" />
				<meta property="og:title" content="Custom Blend App - Personalize Your Products" />
				<meta property="og:description" content="Discover the Custom Blend App - the ultimate tool for creating unique, personalized product blends for your store. Enhance customer experience and boost sales with ease." />
				<meta property="og:image" content={window.location.origin + '/images/banner.png'} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={window.location.href} />
				<meta name="twitter:title" content="Custom Blend App - Personalize Your Products" />
				<meta name="twitter:description" content="Discover the Custom Blend App - the ultimate tool for creating unique, personalized product blends for your store. Enhance customer experience and boost sales with ease." />
				<meta name="twitter:image" content={window.location.origin + '/images/banner.png'} />
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			<section className="bg-white dark:bg-[#F69A29]">
				<div className="grid max-w-screen-xl px-4 pt-6 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-3 lg:pt-10">
					<div className="mr-auto place-self-center lg:col-span-2 col-span-2">
						<h1 className="text-6xl font-extrabold text-white mb-4 leading-tight">
							Create Unique <br />
							Custom Blends
						</h1>
						<p className="text-2xl text-white mb-6">
							Personalize products with ease.
						</p>
						<div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
							<a
								href="https://apps.shopify.com/custom-blends"
								target="_blank"
								className="bg-white text-[#99ae8b] font-bold inline-flex items-center justify-center w-full px-6 py-4 text-lg text-center border rounded-lg sm:w-auto hover:bg-gray-100 transition duration-300"
							>
								<img
									src={shopifylogo}
									alt="Shopify Logo"
									className="w-8 h-8 mr-2"
								/>
								Install App
							</a>
						</div>
					</div>
					<div className="hidden lg:mt-0 lg:flex">
						<img
							src={image}
							alt="Hero Image of Calculator"
							className="max-w-60"
						/>
					</div>
				</div>
			</section>

			<FeatureSection
				title="Add Custom Blend Options to Your Store"
				image={{ url: addItems, alt: "Custom Blend App" }}
			>
				<div className="mx-44">
					The Custom Blend App is a versatile tool designed to help merchants
					create unique, personalized product blends. Whether you run a coffee
					shop, tea store, flower arrangement business, custom cosmetics brand,
					or an herbal store, this app allows you to offer customized blends
					tailored to your customers’ specific preferences. Simplify ingredient
					management, perform real-time calculations, and enhance your product
					offerings effortlessly with the Custom Blend App.
				</div>
			</FeatureSection>

			<FeatureSection
				title="Customize the Calculator"
				image={{ url: Customizer, alt: "Customizer Settings" }}
			>
				With the Custom Blend App, you can customize the calculator to suit your
				store’s unique needs. Add or remove ingredients, adjust pricing, and set
				quantity limits to create a personalized experience for your customers.
				The app’s intuitive interface makes it easy to manage and update your
				custom blend options, so you can focus on growing your business.
			</FeatureSection>

			<section className="features py-10 bg-white">
				<div className="container mx-auto px-4">
					<h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">
						Features
					</h2>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
						<div className="feature text-center">
							<h3 className="text-xl font-bold mb-2">Custom Blend Creation</h3>
							<p>
								Create unique blends that cater to individual tastes and
								preferences.
							</p>
						</div>
						<div className="feature text-center">
							<h3 className="text-xl font-bold mb-2">
								Easy Ingredient Management
							</h3>
							<p>Manage and update ingredient lists effortlessly.</p>
						</div>
						<div className="feature text-center">
							<h3 className="text-xl font-bold mb-2">
								Real-Time Proportion Calculations
							</h3>
							<p>
								Automatically calculate ingredient proportions and totals in
								real-time.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="use-case py-20 bg-gray-100">
				<h2 className="text-4xl font-extrabold text-gray-800 mb-8 text-center">
					Use Cases
				</h2>
				<div className="container mx-auto px-4 flex">
					<div>
						<p className="text-xl text-gray-700 text-center mb-8">
							Custom Blend App is perfect for a variety of Shopify stores,
							including:
						</p>
						<div className="max-w-2xl mx-auto">
							<div className="mb-8">
								<h3 className="text-2xl font-bold text-gray-800 mb-2">
									Coffee Shops
								</h3>
								<p className="text-lg text-gray-700 mb-4">
									Create personalized coffee blends for your customers, offering
									unique flavors and exclusive blends that keep them coming
									back.
								</p>
							</div>
							<div className="mb-8">
								<h3 className="text-2xl font-bold text-gray-800 mb-2">
									Tea Stores
								</h3>
								<p className="text-lg text-gray-700 mb-4">
									Offer custom tea blends with a variety of flavors and
									ingredients, allowing tea enthusiasts to craft their perfect
									cup.
								</p>
							</div>
							<div className="mb-8">
								<h3 className="text-2xl font-bold text-gray-800 mb-2">
									Flower Shops
								</h3>
								<p className="text-lg text-gray-700 mb-4">
									Design unique floral arrangements with custom selected
									flowers, giving your customers the ability to create
									personalized bouquets for special occasions.
								</p>
							</div>
							<div className="mb-8">
								<h3 className="text-2xl font-bold text-gray-800 mb-2">
									Custom Cosmetics
								</h3>
								<p className="text-lg text-gray-700 mb-4">
									Let customers create their own beauty products with specific
									ingredients tailored to their skin type and preferences,
									enhancing their shopping experience.
								</p>
							</div>
							<div className="mb-8">
								<h3 className="text-2xl font-bold text-gray-800 mb-2">
									Herbal Stores
								</h3>
								<p className="text-lg text-gray-700 mb-4">
									Blend different herbs and spices for personalized remedies,
									catering to customers looking for custom herbal solutions.
								</p>
							</div>
							<div className="mb-8">
								<h3 className="text-2xl font-bold text-gray-800 mb-2">
									Cannabis Dispensaries
								</h3>
								<p className="text-lg text-gray-700 mb-4">
									Create custom cannabis blends, allowing customers to
									personalize their products based on their preferences and
									needs.
								</p>
							</div>
							<h3 className="text-2xl font-bold text-gray-800 mb-4">
								Benefits
							</h3>
							<ul className="list-disc list-inside text-lg text-gray-700 mb-8">
								<li>
									<span className="font-semibold">
										Increased Customer Loyalty:
									</span>{" "}
									Offering personalized products helps build stronger
									relationships with your customers, encouraging repeat
									business.
								</li>
								<li>
									<span className="font-semibold">
										Enhanced Shopping Experience:
									</span>{" "}
									Customization options make the shopping experience more
									engaging and enjoyable, leading to higher customer
									satisfaction.
								</li>
								<li>
									<span className="font-semibold">Higher Perceived Value:</span>{" "}
									Custom products are often perceived as higher value, allowing
									you to potentially charge a premium price.
								</li>
								<li>
									<span className="font-semibold">Competitive Advantage:</span>{" "}
									Stand out from competitors by offering unique, personalized
									products that others may not provide.
								</li>
							</ul>
							<h3 className="text-2xl font-bold text-gray-800 mb-4">
								Who Can Use It?
							</h3>
							<p className="text-lg text-gray-700">
								The Custom Blend App is designed for any Shopify store owner who
								wants to offer personalized product options. Whether you are in
								the food and beverage industry, floral business, cosmetic
								sector, or herbal market, this app can help you enhance your
								product offerings and provide a unique experience for your
								customers.
							</p>
						</div>
					</div>
					<div className="flex-1">
						<select onChange={updateBlendConfig} className="blend-selector">
							<option value="coffee">Coffee</option>
							<option value="tea">Tea</option>
							<option value="flowers">Flowers</option>
							<option value="cosmetics">Cosmetics</option>
							<option value="herbs">Herbs</option>
						</select>
						<div className="blend-demo bg-white">
							{
								demoSelected && (
									<BlendMaker
										config={config}
										demo_mode={true}
										demoItems={ingredients}
									/>
								)
							} 
							{
								! demoSelected && (
									<div className="blend-demo-placeholder p-6">
										Select a store type to view a demo.
									</div>
								)
							}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default LandingPage;
