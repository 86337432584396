const FeatureSection = function({ id, title, image, children }) {
    return (
        <section id={id} className="about py-24 bg-white">
            <div className="container mx-auto px-4">
                <div className="w-lg-75 text-center mx-lg-auto mb-7 mb-md-10">
                <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
                    {title}
                </h2>
                </div>
                <div className="text-gray-700 text-center mb-8">
                    {children}
                </div>
                <div className="text-center">
                    <img src={image.url} alt={image.alt} className="max-w-4xl inline-block" />
                </div>
            </div>
        </section>
    );
}

export default FeatureSection;