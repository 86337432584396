// useDocumentTitle.js
import { useRef, useEffect } from "react";

const useDocumentTitle = function (
	title,
	meta = { title: "", description: "" },
	prevailOnUnmount = false
) {
	const defaultTitle = useRef(document.title);
	const metaTitle = useRef(document.querySelector('meta[property="og:title"]'));
	const metaDescription = useRef(
		document.querySelector('meta[property="og:description"]')
	);
	useEffect(() => {
		document.title = title;
		/*if (metaTitle.current) {
			metaTitle.current.content = title;
		}
		if (metaDescription.current) {
			metaDescription.current.content = meta.description;
		}*/
	}, [title, meta.title, meta.description]);

	useEffect(
		() => () => {
			if (!prevailOnUnmount) {
				document.title = defaultTitle.current;
				//metaTitle.current.content = meta.title;
				//metaDescription.current.content = meta.description;
			}
		},
		[]
	);
};

export { useDocumentTitle };

export const preloadImages = (images) => {
	// Preload images in head tag
	const head = document.head;
	images.forEach((src) => {
		const link = document.createElement("link");
		link.rel = "preload";
		link.href = src;
		link.as = "image";
		head.appendChild(link);
	});
}
 
