import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.webp';

const Footer = () => {
    return (
        <footer className="footer bg-[#F69A29]">
            <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row justify-between items-center">
                <div className="logo text-2xl font-bold text-[#AD1E2E] mb-4 md:mb-0">
                    <Link to="/"><img src={logo} alt="Custom Blend App Logo" className="w-44" width={176} height={56} /></Link>
                </div>
                <nav className="menu flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 text-center">
                    <Link to="/" className="menu-item text-white">
                        Home
                    </Link>
                    <Link to="/privacy" className="menu-item text-white">
                        Privacy Policy
                    </Link>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
