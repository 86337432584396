import React from "react";
const Page404 = () => {
	return (
		<div>
			<h1>404</h1>
			<p>Page not found.</p>
		</div>
	);
};
export default Page404;
